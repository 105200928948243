import {ModelManager} from "../manager/ModelManager";
import {Area, Point} from "./GeometryUtils";
import {ArchimateElementType} from "../../archimate/ArchimateElement";
import {ArchimateRelationship, ArchimateRelationshipType} from "../../archimate/ArchimateRelationship";
import {IDiagramNodeDto} from "../../apis/diagram/IDiagramNodeDto";
import {IDiagramConnectionDto} from "../../apis/diagram/IDiagramConnectionDto";
import {NodeType} from "../../apis/diagram/NodeType";
import {RelationshipDto} from "../../apis/relationship/RelationshipDto";

export const SELECTED_NODE_GROUP_STROKE_WIDTH = 3;

export class DiagramEditorUtils {

    static getNodeLabel(node: IDiagramNodeDto, modelManager: ModelManager) {
        let label = node.label;
        if (!label && node.elementIdentifier) {
            label = modelManager.getElementById(node.elementIdentifier).name || "";
        }
        return label || "";
    }

    static setNodeLabel(node: IDiagramNodeDto, label: string, modelManager: ModelManager) {
        if (node.elementIdentifier) {
            const element = modelManager.getElementById(node.elementIdentifier);
            if (element) {
                element.name = label;
            }
            if (node.label) {
                node.label = label;
            }
        } else {
            node.label = label;
        }
    }


    static getConnectionLabel(connection: IDiagramConnectionDto, modelManager: ModelManager) {
        const relationship = modelManager.getRelationshipById(connection.relationshipIdentifier);
        let label = connection.label;
        if (!label && relationship) {
            label = relationship.name || "";
        }
        if (relationship && relationship.type) {
            const type = ArchimateRelationship.findByStandardName(relationship.type)?.relationshipType;
            if (type === ArchimateRelationshipType.INFLUENCE && relationship.influenceModifier) {
                let enhancedLabel = `${relationship.influenceModifier}`;
                if (label) {
                    enhancedLabel += `(${label})`;
                }
                label = enhancedLabel;
            }
        }
        return label || "";
    }

    static getFormattedRelationshipType(relationship: RelationshipDto | null) {
        const relationshipType = relationship && ArchimateRelationship.findByStandardName(relationship.type);
        return (relationshipType && relationshipType.visibleName);
    }

    static setConnectionLabel(connection: IDiagramConnectionDto, label: string, modelManager: ModelManager) {
        if (connection.relationshipIdentifier) {
            const element = modelManager.getRelationshipById(connection.relationshipIdentifier);
            if (element) {
                element.name = label;
            }
            if (connection.label) {
                connection.label = label;
            }
        } else {
            connection.label = label;
        }
    }

    static convertPointFromGroupToGroup(sourcePoint: Point, sourceGroup: SVGGElement | SVGSVGElement, targetGroup: SVGGElement | SVGSVGElement, svg: SVGSVGElement): Point {
        const toScreenTransform = sourceGroup.getScreenCTM() as DOMMatrix;

        let point = svg.createSVGPoint();
        point.x = sourcePoint.x;
        point.y = sourcePoint.y;
        point = point.matrixTransform(toScreenTransform);

        const toGridTransform = targetGroup.getScreenCTM()?.inverse() as DOMMatrix;
        point = point.matrixTransform(toGridTransform);

        return new Point(point.x, point.y);
    }

    static convertPointsFromGroupToGroup(sourcePoints: Array<Point>, sourceGroup: SVGGElement | SVGSVGElement, targetGroup: SVGGElement | SVGSVGElement, svg: SVGSVGElement): Array<Point> {
        return sourcePoints.map(point => DiagramEditorUtils.convertPointFromGroupToGroup(point, sourceGroup, targetGroup, svg));
    }

    static getGroupBBoxEdgePoints(sourceGroup: SVGGElement | SVGSVGElement): [Point, Point, Point, Point] {
        const bBox = sourceGroup.getBBox();
        return [
            new Point(bBox.x, bBox.y),
            new Point(bBox.x + bBox.width, bBox.y),
            new Point (bBox.x + bBox.width, bBox.y + bBox.height),
            new Point(bBox.x, bBox.y + bBox.height)
        ]
    }

    static convertGroupBBoxEdgePointsToSvgPoints(sourceGroup: SVGGElement | SVGSVGElement, svg: SVGSVGElement): [Point, Point, Point, Point] {
        const points = DiagramEditorUtils.getGroupBBoxEdgePoints(sourceGroup);
        return DiagramEditorUtils.convertPointsFromGroupToGroup(points, sourceGroup, svg, svg) as [Point, Point, Point, Point];
    }

    static convertGroupPointToScreenCoordinates(group: SVGGElement, point: Point, svg: SVGSVGElement) {
        const toScreenTransform = group.getScreenCTM() as DOMMatrix;

        let svgPoint = svg.createSVGPoint();
        svgPoint.x = point.x;
        svgPoint.y = point.y;
        svgPoint = svgPoint.matrixTransform(toScreenTransform);

        return new Point(svgPoint.x, svgPoint.y);
    }

    static convertOuterPointToGroup(outerPoint: Point, targetGroup: SVGGElement | SVGSVGElement, svg: SVGSVGElement) {
        const toGroupTransform = targetGroup.getScreenCTM()?.inverse() as DOMMatrix;

        let point = svg.createSVGPoint();
        point.x = outerPoint.x;
        point.y = outerPoint.y;
        point = point.matrixTransform(toGroupTransform);

        return new Point(point.x, point.y);
    }

    static isTouchDevice() {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0));
    }

    static convertEdgePointsToArea(edgePoints: [Point, Point, Point, Point]) {
        return new Area(edgePoints[0].x, edgePoints[0].y, Math.abs(edgePoints[2].x - edgePoints[0].x), Math.abs(edgePoints[2].y - edgePoints[0].y))
    }

    static isDiagramReferenceNode(node: IDiagramNodeDto) {
        return node && node.type === NodeType.LABEL && node.diagramReferences != null && node.diagramReferences.length > 0;
    }

    static isNoteNode(node: IDiagramNodeDto) {
        return node && node.type === NodeType.LABEL && !DiagramEditorUtils.isDiagramReferenceNode(node);
    }

    static isLabelNodeType(node: IDiagramNodeDto) {
        return node.type === NodeType.LABEL;
    }

    static isJunctionNodeType(node: IDiagramNodeDto, modelManager: ModelManager) {
        const elementType = modelManager.getElementType(node.elementIdentifier);

        return elementType === ArchimateElementType.OR_JUNCTION || elementType === ArchimateElementType.AND_JUNCTION;
    }


}
