import React, {useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import CommonCssStyles from "../../../../css/CommonCssStyles";
import Grid from "../../../../components/dialogs/Grid";
import RenderMode from "../diagrams/RenderMode";
import TextField from "../../../../components/fields/textfield/TextField";
import {_transl} from "../../../../store/localization/TranslMessasge";
import SearchFab from "../../../../components/button/SearchFab";
import {GraphQueryTranslationKey} from "./GraphQueryTranslationKey";
import {GraphQueryFilterDto} from "../../../../common/apis/query/GraphQueryFilterDto";
import SingleselectComboBox from "../../../../components/fields/SingleselectComboBox";
import {GraphQueryFormatter} from "./GraphQueryFormatter";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        page: CommonCssStyles.getRootPageStyles(theme),
        headerPageSegment: CommonCssStyles.getHeaderPageSegmentStyles(theme),
        controlPageSegment: CommonCssStyles.getControlPageSegmentStyles(theme),
        formGridItem: {
            display: "flex",
            flexDirection: "column",
            "&> *": {
                flex: 1,
            }
        },
        searchActionGridItem: {
            display: 'flex',
            justifyContent: "flex-end",
        },
    })
);

export interface GraphQueryFilterProps {
    onFilterChange: (filter: GraphQueryFilterDto) => void;
    renderMode: RenderMode,
}

export default function GraphQueryFilter({onFilterChange}: GraphQueryFilterProps) {

    const classes = useStyles();

    const [queryFilter, setQueryFilter] = useState<GraphQueryFilterDto>({
        nameLike: "",
        isPrivate: undefined
    });

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        onFilterChange(queryFilter);
    };

    return (
        <>
            <form onSubmit={(event: React.FormEvent) => onSubmit(event)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3} lg={3} className={classes.formGridItem}>
                        <TextField
                            id="filter-query-name"
                            label={_transl(GraphQueryTranslationKey.NAME)}
                            value={queryFilter.nameLike}
                            onChange={nameLike => setQueryFilter({...queryFilter, nameLike})}
                            clearable
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3} className={classes.formGridItem}>
                        <SingleselectComboBox id={"filter-query-access"}
                                              label={_transl(GraphQueryTranslationKey.ACCESS)}
                                              required={false}
                                              options={[true, false] as boolean[]}
                                              getRenderLabel={(value) => GraphQueryFormatter.formatIsPrivate(value, _transl)}
                                              handleOnChange={isPrivate => setQueryFilter({...queryFilter, isPrivate})}
                                              defaultValue={undefined}
                        />
                    </Grid>
                    <Grid item xs={12} sm md lg className={classes.searchActionGridItem}>
                        <SearchFab inProgress={false}/>
                    </Grid>
                </Grid>
            </form>

        </>

    );
}
