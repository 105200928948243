import React, {useEffect, useState} from "react";
import {ProjectDto} from "@dain/tracker-client";
import TrackerService from "./TrackerService";
import TextField from "../../../../../components/fields/textfield/TextField";
import MultiselectComboBox from "../../../../../components/fields/MultiselectComboBox";
import {TrackerTranslationKey} from "./TrackerTranslationKey";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import Grid from "../../../../../components/dialogs/Grid";
import SearchFab from "../../../../../components/button/SearchFab";
import {CommonTranslation} from "../../CommonTranslation";
import Snackbar from "../../snackbar/Snackbar";
import {ErrorTranslationKey} from "../../ErrorTranslationKey";

export interface AssignExistingTaskFilterProps {
    trackerService: TrackerService
    onSearchClicked: (name: string, projects: ProjectDto[]) => void;
}

export default function AssignExistingTaskFilter(props: AssignExistingTaskFilterProps) {

    const [availableProjects, setAvailableProjects] = useState<ProjectDto[]>([]);
    const [nameLike, setNameLike] = useState<string>("");
    const [projects, setProjects] = useState<ProjectDto[]>([]);
    const [projectErrorMessage, setProjectErrorMessage] = useState<string>();

    const {onSearchClicked, trackerService} = props;


    useEffect(() => {
        trackerService.topicsApi.getActiveProjectsUsingGET()
                .then((response => {
                    setAvailableProjects(response.resultList as ProjectDto[]);
                }))
                .catch(error => {
                    setAvailableProjects([]);
                    Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_LOAD_DATA));
                });
    }, [trackerService]);

    function handleOnSearchButtonClicked() {
        if (validate()) {
            onSearchClicked(nameLike, projects);
        }
    }

    function validate(): boolean {
        if (projects.length === 0) {
            setProjectErrorMessage(_transl(CommonTranslation.FILL_OUT_AT_LEAST_ONE))
            return false;
        } else {
            setProjectErrorMessage(undefined);
            return true;
        }
    }

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        handleOnSearchButtonClicked();
    };

    return (
        <>
            <form onSubmit={(event: React.FormEvent) => onSubmit(event)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <TextField
                            id="assign-existing-task-filter-name-field"
                            label={_transl(TrackerTranslationKey.FILTER_FIELD_TASK_NAME)}
                            variant="outlined"
                            size={"small"}
                            InputLabelProps={{shrink: true}}
                            value={nameLike}
                            onChange={setNameLike}
                            clearable
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <MultiselectComboBox
                            label={_transl(TrackerTranslationKey.FILTER_FIELD_THEME)}
                            id="assign-existing-task-filter-projects-multiselectcombobox"
                            options={availableProjects}
                            selectedValues={projects || []}
                            getRenderLabel={(project) => project.name || ""}
                            handleOnChange={setProjects}
                            required={true}
                            errorMessage={projectErrorMessage}
                        />
                    </Grid>
                    <Grid item xs={12} sm md lg style={{display: 'flex', justifyContent: "flex-end"}}>
                        <SearchFab/>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}
