import EventManager, {Unsubscriber} from "../../../event/EventManager";
import RenderContext from "../../context/RenderContext";
import * as d3 from "d3";
import {EventProperty, EventType, DiagramZoomEvent, ModelBackupEvent} from "../../../event/Event";
import PositionManagerUtils from "./PositionManagerUtils";
import {CUSTOM_DRAG_HANDLER, ICustomDragHandler,} from "../SvgElementDragManager";
import {Area, Point} from "../../util/GeometryUtils";
import {ArchimateElementType} from "../../../archimate/ArchimateElement";
import {
    DISABLE_ON_NODE_MOVE_POINTER_EVENTS_ALL_CLASS_NAME,
    HANDLE_FILL_COLOR, HANDLE_STROKE_COLOR
} from "../../common/UIConstants";
import {IDiagramNodeDto} from "../../../apis/diagram/IDiagramNodeDto";
import RenderMode from "../../context/RenderMode";

export enum ResizeHandleType {
    N = "N",
    S = "S",
    E = "E",
    W = "W",
    NW = "NW",
    NE = "NE",
    SE = "SE",
    SW = "SW",
}

enum ResizeCircleType {
    SMALL = "SMALL",
    LARGE = "LARGE",
    DRAG = "DRAG",
}

export default class PositionHandle {

    private static readonly NODE_RESIZE_START_THRESHOLD = 0;

    private static readonly VERTICAL_MOVE_HANDLE_HEIGHT = 9;
    private static readonly SMALL_RESIZE_HANDLE_FILL = HANDLE_FILL_COLOR;
    private static readonly LARGE_RESIZE_HANDLE_FILL = HANDLE_FILL_COLOR;
    private static readonly SMALL_RESIZE_HANDLE_RADIUS = 4;
    private static readonly LARGE_RESIZE_HANDLE_RADIUS = 5;
    private static readonly DRAG_RESIZE_HANDLE_RADIUS = 7;
    private static readonly NODE_REMOVE_HANDLE_RADIUS = 8;
    private static readonly RESIZE_HANDLE_CLASS_NAME = "__selection_resize_handle__";
    private static readonly RESIZE_CIRCLE_TYPE_PROPERTY_NAME = "__selection_resize_circle_type__";

    private eventManager: EventManager;
    private renderContext?: RenderContext;
    private scale: number;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.DIAGRAM_ZOOM_UPDATED, this.handleChartZoomEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<ModelBackupEvent>(EventType.MODEL_UPDATED_ON_MODEL_BACKUP_LOADED, this.handleModelUpdatedOnModelBackupLoadedEvent.bind(this)));

        this.scale = 1;
    }

    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    init(renderContext: RenderContext) {
        this.renderContext = renderContext;
    }

    initPositionHandle(node: IDiagramNodeDto) {
        if (this.renderContext) {
            const positionHandlesGroup = PositionManagerUtils.getNodesHandleGroup();
            positionHandlesGroup.append("g")
                .attr("id", PositionManagerUtils.createNodeHandleGroupId(node))
                .datum(node);
        }
    }

    removePositionHandle(node: IDiagramNodeDto) {
        if (this.renderContext) {
            d3.select(PositionManagerUtils.createNodeHandleGroupId(node, true)).remove();
        }
    }

    hidePositionHandles(nodes: IDiagramNodeDto[]) {
        nodes.forEach(node => {
            const positionHandleGroup = d3.select(PositionManagerUtils.createNodeHandleGroupId(node, true)) as d3.Selection<SVGGElement, IDiagramNodeDto, HTMLElement, undefined>;
            positionHandleGroup.selectAll("*").remove();
        })
    }

    showPositionHandles(nodes: IDiagramNodeDto[]) {
        if (this.renderContext) {
            nodes.forEach(node => {
                const positionHandleGroup = d3.select(PositionManagerUtils.createNodeHandleGroupId(node, true)) as d3.Selection<SVGGElement, IDiagramNodeDto, HTMLElement, undefined>;
                if (positionHandleGroup.selectChildren("*").empty()) {
                    PositionHandle.appendDashedBorder(positionHandleGroup, node);
                    this.appendMoveHandles(positionHandleGroup, node);
                    if (!this.isJunctionNode(node)) {
                        this.appendHorizontalResizeHandles(positionHandleGroup, node);
                        this.appendVerticalResizeHandles(positionHandleGroup, node);
                    }
                    this.appendResizeHandles(positionHandleGroup, node);
                }
            });
        }
    }

    private static appendDashedBorder(positionHandleGroup: d3.Selection<SVGGElement, IDiagramNodeDto, HTMLElement, undefined>,
                                      node: IDiagramNodeDto) {
        positionHandleGroup.append("rect")
            .attr("fill", "none")
            .attr("stroke", HANDLE_STROKE_COLOR)
            .attr("stroke-dasharray", "5 3")
            .attr("stroke-width", "0.5px")
            .attr("x", node.x)
            .attr("y", node.y)
            .attr("width", node.w)
            .attr("height", node.h)
            .attr("pointer-events", "none");
    }

    private appendMoveHandles(positionHandleGroup: d3.Selection<SVGGElement, IDiagramNodeDto, HTMLElement, undefined>,
                              node: IDiagramNodeDto) {
        const space = PositionHandle.VERTICAL_MOVE_HANDLE_HEIGHT;
        const spaceHalf = (space / 2);

        // left move handle
        let handle = new Area(node.x - spaceHalf, node.y + spaceHalf, space, node.h - (space));
        this.createMoveHandle(handle, positionHandleGroup, node);

        // right move handle
        handle = new Area(node.x + node.w - spaceHalf, node.y + spaceHalf, space, node.h - (space));
        this.createMoveHandle(handle, positionHandleGroup, node);

        // top move handle
        handle = new Area(node.x + (space / 2), node.y - spaceHalf, node.w - (space), space);
        this.createMoveHandle(handle, positionHandleGroup, node);

        // bottom move handle
        handle = new Area(node.x + (space / 2), node.y + node.h - spaceHalf, node.w - (space), space);
        this.createMoveHandle(handle, positionHandleGroup, node);
    }

    private createMoveHandle(area: Area,
                             selectionHandleGroup: d3.Selection<SVGGElement, IDiagramNodeDto, HTMLElement, undefined>,
                             node: IDiagramNodeDto) {
        const moveHandle = selectionHandleGroup.append("rect")
            .classed(DISABLE_ON_NODE_MOVE_POINTER_EVENTS_ALL_CLASS_NAME, true)
            .attr("fill", "none")
            .attr("stroke", "none")
            .attr("x", area.x)
            .attr("y", area.y)
            .attr("width", area.w)
            .attr("height", area.h)
            .attr("cursor", "move");

        if (this.renderContext) {
            PositionHandle.addMoveEventPublisher(moveHandle, node, this.eventManager, this.renderContext.renderMode.mode);
        }
    }

    private appendHorizontalResizeHandles(selectionHandleGroup: d3.Selection<SVGGElement, IDiagramNodeDto, HTMLElement, undefined>,
                                          node: IDiagramNodeDto) {
        // left
        let centerPoint = new Point(node.x, node.y + (node.h / 2));
        this.appendResizeHandle(centerPoint, true, selectionHandleGroup, node, ResizeHandleType.W, "ew-resize");

        // right
        centerPoint = new Point(node.x + node.w, node.y + (node.h / 2));
        this.appendResizeHandle(centerPoint, true, selectionHandleGroup, node, ResizeHandleType.E, "ew-resize");
    }

    private appendVerticalResizeHandles(selectionHandleGroup: d3.Selection<SVGGElement, IDiagramNodeDto, HTMLElement, undefined>,
                                        node: IDiagramNodeDto) {
        // top
        let centerPoint = new Point(node.x + (node.w / 2), node.y);
        this.appendResizeHandle(centerPoint, true, selectionHandleGroup, node, ResizeHandleType.N, "ns-resize");

        // bottom
        centerPoint = new Point(node.x + (node.w / 2), node.y + node.h);
        this.appendResizeHandle(centerPoint, true, selectionHandleGroup, node, ResizeHandleType.S, "ns-resize");
    }

    private appendResizeHandles(selectionHandleGroup: d3.Selection<SVGGElement, IDiagramNodeDto, HTMLElement, undefined>,
                                node: IDiagramNodeDto) {
        // left top
        let centerPoint = new Point(node.x, node.y);
        this.appendResizeHandle(centerPoint, false, selectionHandleGroup, node, ResizeHandleType.NW,"nwse-resize");

        // right top
        centerPoint = new Point(node.x + node.w, node.y);
        this.appendResizeHandle(centerPoint, false, selectionHandleGroup, node, ResizeHandleType.NE,"nesw-resize");

        // left bottom
        centerPoint = new Point(node.x, node.y + node.h);
        this.appendResizeHandle(centerPoint, false, selectionHandleGroup, node, ResizeHandleType.SW, "nesw-resize");

        // right bottom
        centerPoint = new Point(node.x + node.w, node.y + node.h);
        this.appendResizeHandle(centerPoint, false, selectionHandleGroup, node, ResizeHandleType.SE, "nwse-resize");
    }

    private appendResizeHandle(centerPoint: Point,
                               isSmall: boolean,
                               selectionHandleGroup: d3.Selection<SVGGElement, IDiagramNodeDto, HTMLElement, undefined>,
                               node: IDiagramNodeDto,
                               resizeType: ResizeHandleType,
                               cursor: string) {
        const visibleHandlePartFillColor = PositionHandle.getHandleFillColor(isSmall);
        const resizeCircleType = isSmall ? ResizeCircleType.SMALL : ResizeCircleType.LARGE;

        this.createResizeHandleCircle(resizeCircleType, centerPoint, visibleHandlePartFillColor,
            HANDLE_STROKE_COLOR, cursor, selectionHandleGroup);

        // create larger invisible handle on top of a visible one and attach resize handlers to it
        const largeHandle = this.createResizeHandleCircle(ResizeCircleType.DRAG, centerPoint, "none", "none", cursor, selectionHandleGroup);
        largeHandle.classed(DISABLE_ON_NODE_MOVE_POINTER_EVENTS_ALL_CLASS_NAME, true);
        largeHandle.attr("pointer-events", "all");
        if (this.renderContext?.renderMode.mode === RenderMode.EDIT) {
            PositionHandle.addResizeEventPublisher(largeHandle, node, resizeType, this.eventManager);
        }
    }

    private getHandleRadius(type: ResizeCircleType) {
        let scaleFactor = this.getHandleScaleFactor();
        if (type === ResizeCircleType.SMALL) {
            return PositionHandle.SMALL_RESIZE_HANDLE_RADIUS * scaleFactor;
        } else if (type === ResizeCircleType.LARGE) {
            return PositionHandle.LARGE_RESIZE_HANDLE_RADIUS * scaleFactor;
        } else {
            // drag type
            return PositionHandle.DRAG_RESIZE_HANDLE_RADIUS * scaleFactor;
        }
    }

    private getHandleScaleFactor(): number {
        return 1 / this.scale;
    }

    private static getHandleFillColor(isSmall: boolean) {
        if (isSmall) {
            return PositionHandle.SMALL_RESIZE_HANDLE_FILL;
        } else {
            return PositionHandle.LARGE_RESIZE_HANDLE_FILL;
        }
    }

    private createResizeHandleCircle(type: ResizeCircleType,
                                     centerPoint: Point,
                                     fill: string,
                                     stroke: string,
                                     cursor: string,
                                     positionHandleGroup: d3.Selection<SVGGElement, IDiagramNodeDto, HTMLElement, undefined>,) {
        const radius = this.getHandleRadius(type);
        return positionHandleGroup.append("circle")
            .classed(PositionHandle.RESIZE_HANDLE_CLASS_NAME, true)
            .property(PositionHandle.RESIZE_CIRCLE_TYPE_PROPERTY_NAME, type)
            .attr("r", radius)
            .attr("cx", centerPoint.x)
            .attr("cy", centerPoint.y)
            .attr("fill", fill)
            .attr("stroke", stroke)
            .attr("stroke-width", this.getHandleScaleFactor() * 0.5)
            .attr("cursor", cursor)
            .attr("pointer-events", "none");
    }

    synchronizePositionHandles(nodes: Array<IDiagramNodeDto>, selectedNodes: Array<IDiagramNodeDto>) {
        this.hidePositionHandles(nodes);
        nodes.forEach(node => {
            if (selectedNodes.indexOf(node) !== -1) {
                this.showPositionHandles([node]);
            }
        });
    }

    private handleChartZoomEvent(event: DiagramZoomEvent) {
        if (event.type === EventType.DIAGRAM_ZOOM_UPDATED) {
            const scale = event.actualZoom;
            this.updateScale(scale);
        }
    }

    private updateScale(scale: number) {
        if (this.scale !== scale) {
            this.scale = scale;

            d3.selectAll(`circle.${PositionHandle.RESIZE_HANDLE_CLASS_NAME}`)
                .attr("r", (d, i, circles ) => {
                    const circleType: ResizeCircleType = d3.select(circles[i]).property(PositionHandle.RESIZE_CIRCLE_TYPE_PROPERTY_NAME);
                    return this.getHandleRadius(circleType);
                })
                .attr("stroke-width", this.getHandleScaleFactor())
        }
    }

    private handleModelUpdatedOnModelBackupLoadedEvent(event: ModelBackupEvent) {
        for (const node of event.oldModelAccessor.getAllNodes()) {
            this.removePositionHandle(node);
        }
        for (const node of event.newModelAccessor.getAllNodes()) {
            this.initPositionHandle(node);
        }
    }


    // EVENT PUBLISHERS

    public static addMoveEventPublisher(selection: d3.Selection<any, IDiagramNodeDto, any, undefined>,
                                        node: IDiagramNodeDto,
                                        eventManager: EventManager,
                                        mode: RenderMode,
                                        clickThreshold?: number,
                                        publishClickEvent?: (event: any) => void)
    {
        const isEdit = mode === RenderMode.EDIT;
        const dragHandler: ICustomDragHandler = {
            useSnappingFunction: true,
            publishStartEvent: (event: any) => {
                isEdit && eventManager.publishEvent({
                    type: EventType.NODE_MOVE_STARTED,
                    event: event,
                    node: node as IDiagramNodeDto
                });
            },
            publishInProgressEvent: (event: any) => {
                isEdit && eventManager.publishEvent({
                    type: EventType.NODE_MOVE_IN_PROGRESS,
                    event: event,
                    node: node as IDiagramNodeDto
                });
            },
            publishEndEvent: (event: any) => {
                isEdit && eventManager.publishEvent({
                    type: EventType.NODE_MOVE_FINISHED,
                    event: event,
                    node: node as IDiagramNodeDto
                });
            },
            publishCancelEvent: (event: any) => {
                isEdit && eventManager.publishEvent({
                    type: EventType.NODE_MOVE_CANCELLED,
                    event: event,
                    node: node as IDiagramNodeDto
                });
            },
            clickThreshold: clickThreshold,
            publishClickEvent: publishClickEvent,
            setTransformedEventCoordinates: (event: any, transformedX: number, transformedY: number) => {
                event[EventProperty.TRANSFORMED_X_COORDINATE] = transformedX;
                event[EventProperty.TRANSFORMED_Y_COORDINATE] = transformedY;
            }
        }
        selection.node()[CUSTOM_DRAG_HANDLER] = dragHandler;
    }

    private static addResizeEventPublisher(selection: d3.Selection<any, IDiagramNodeDto, any, undefined>,
                                           node: IDiagramNodeDto,
                                           resizeType: ResizeHandleType,
                                           eventManager: EventManager) {
        const dragHandler: ICustomDragHandler = {
            useSnappingFunction: true,
            publishStartEvent: (event: DragEvent) => {
                eventManager.publishEvent({
                    type: EventType.NODE_RESIZE_STARTED,
                    event: event,
                    node: node as IDiagramNodeDto,
                    resizeType: resizeType,
                });
            },
            publishInProgressEvent: (event: DragEvent) => {
                eventManager.publishEvent({
                    type: EventType.NODE_RESIZE_IN_PROGRESS,
                    event: event,
                    node: node as IDiagramNodeDto,
                    resizeType: resizeType,
                });
            },
            publishEndEvent: (event: DragEvent) => {
                eventManager.publishEvent({
                    type: EventType.NODE_RESIZE_FINISHED,
                    event: event,
                    node: node as IDiagramNodeDto,
                    resizeType: resizeType,
                });
            },
            publishCancelEvent: (event: DragEvent) => {
                eventManager.publishEvent({
                    type: EventType.NODE_RESIZE_CANCELLED,
                    event: event,
                    node: node as IDiagramNodeDto,
                    resizeType: resizeType,
                });
            },
            dragStartThreshold: PositionHandle.NODE_RESIZE_START_THRESHOLD,
            setTransformedEventCoordinates: (event: any, transformedX: number, transformedY: number) => {
                event[EventProperty.TRANSFORMED_X_COORDINATE] = transformedX;
                event[EventProperty.TRANSFORMED_Y_COORDINATE] = transformedY;
            }
        };
        selection.node()[CUSTOM_DRAG_HANDLER] = dragHandler;
    }

    private isJunctionNode(node: IDiagramNodeDto) {
        const elementType = this.renderContext?.modelManager.getElementType(node.elementIdentifier);
        return elementType === ArchimateElementType.OR_JUNCTION || elementType === ArchimateElementType.AND_JUNCTION;
    }
}
