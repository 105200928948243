import {DefaultDiagramBackupService, DiagramBackupService} from "../DiagramBackupService";
import {createContext, useContext, useEffect, useRef, useState} from "react";
import {DiagramBackupInfo} from "../DiagramBackupInfo";
import EditDiagramBackupDialog from "./EditDiagramBackupDialog";
import {DefaultCheckIntervalFactory, IntervalBasedDiagramBackupPersister} from "../DiagramBackupPersister";
import {LocalStorageBasedDiagramBackupStorage} from "../DiagramBackupStorage";
import {useNavigate} from "react-router-dom";
import RouteDefinitionUtils from "../../../routedefinition/RouteDefinitionUtils";
import {DiagramsGridAction, DiagramsGridActionType} from "../../../../pages/main/content/diagrams/DiagramsGridAction";
import RenderMode from "../../context/RenderMode";
import {ChatCoordinatesManager} from "../../../../pages/main/content/chat/ChatCoordinatesManager";
import {UPDATE_BACKUP_DATE_INTERVAL_IN_MS} from "../../../../pages/main/content/diagrams/DiagramEditorDialog";
import {useSelector} from "react-redux";
import {IApplicationState} from "../../../../store/Store";

const checkIntervalFactory = new DefaultCheckIntervalFactory();

const diagramBackupService = new DefaultDiagramBackupService(
    new IntervalBasedDiagramBackupPersister(
        new LocalStorageBasedDiagramBackupStorage(localStorage), checkIntervalFactory.nextCheckInterval()));

const DiagramBackupServiceContext = createContext<DiagramBackupService>(diagramBackupService);

export function useDiagramBackupService() {
    return useContext(DiagramBackupServiceContext);
}

type Props = {}

export default function DiagramBackupProvider(props: React.PropsWithChildren<Props>) {
    const [diagramBackupsToBeChecked, setDiagramBackupsToBeChecked] = useState<Array<DiagramBackupInfo>>([]);
    const editDiagramBackupCheckDone = useRef<boolean>(false);
    const chatCoordinatesManager = useRef(new ChatCoordinatesManager());

    const user = useSelector((application: IApplicationState) => application.user.userData);

    const backupValidityTolerance = UPDATE_BACKUP_DATE_INTERVAL_IN_MS + DefaultCheckIntervalFactory.CONSTANT_CHECK_INTERVAL_IN_MS + DefaultCheckIntervalFactory.RANDOM_CHECK_INTERVAL_IN_MS_MAX;

    const navigate = useNavigate();

    const backupToBeChecked = getBackupToBeChecked();

    useEffect(() => {
        const backups = diagramBackupService.findUserBackups(user!.login);
        if (backups.length > 0 && !editDiagramBackupCheckDone.current) {
            editDiagramBackupCheckDone.current = true;
            setDiagramBackupsToBeChecked(backups);
        }
    }, [user])

    function onEditBackup(backupToBeChecked: DiagramBackupInfo) {
        setDiagramBackupsToBeChecked([]);
        const queryParams = [
            {
                name: DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.SHOW_EDITOR),
                value: backupToBeChecked.getDiagramId()
            },
            {name: DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.EDITOR_MODE), value: RenderMode.EDIT},
            {name: DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.EDIT_BACKUP), value: "true"},
        ];

        diagramBackupService.updateBackupDate(backupToBeChecked.getDiagramId(), new Date());

        const url = new URL(window.location.href);
        const diagramId = url.searchParams.get(DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.SHOW_EDITOR));
        const editorModeParam = url.searchParams.get(DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.EDITOR_MODE));
        const isDiagramEditor = diagramId != null && editorModeParam === RenderMode.EDIT;

        if (isDiagramEditor) {
            navigate(RouteDefinitionUtils.resolveDiagramEditorPath(queryParams));
        } else {
            window.open(RouteDefinitionUtils.resolveDiagramEditorPath(queryParams), '_blank');
        }
    }

    async function onDiscardBackup(backupToBeChecked: DiagramBackupInfo) {
        const diagramId = backupToBeChecked.getDiagramId();
        await chatCoordinatesManager.current.fixAllChatCoordinatesForDiagram(diagramId);
        diagramBackupService.removeBackup(diagramId);
        setDiagramBackupsToBeChecked([]);
    }

    function getBackupToBeChecked(): DiagramBackupInfo | undefined {
        const url = new URL(window.location.href);

        const editBackup = url.searchParams.get(DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.EDIT_BACKUP));
        if (editBackup === "true") {
            return undefined;
        }

        const diagramId = url.searchParams.get(DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.SHOW_EDITOR));
        const isDiagramEditor = diagramId != null;

        return isDiagramEditor ? diagramBackupsToBeChecked.find(dbi => dbi.getDiagramId() === diagramId && isBackupValid(dbi.getDate()))
            : diagramBackupsToBeChecked.find(dbi => isBackupValid(dbi.getDate()));
    }

    function isBackupValid(dateToCheck: Date): boolean {
        const currentDate = new Date();
        const currentDateMinusTolerance = new Date(currentDate.getTime() - backupValidityTolerance);
        return dateToCheck < currentDateMinusTolerance;
    }

    return <DiagramBackupServiceContext.Provider value={diagramBackupService}>
        {backupToBeChecked &&
            <EditDiagramBackupDialog diagramBackupInfo={backupToBeChecked}
                                     onEditBackup={() => onEditBackup(backupToBeChecked)}
                                     onDiscardBackup={() => onDiscardBackup(backupToBeChecked)}/>}
        {props.children}
    </DiagramBackupServiceContext.Provider>
}
