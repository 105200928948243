import diagramService from "../diagrams/service/DiagramService";
import chatService, {ChatDto} from "../../../../common/apis/chat/ChatService";
import {ChatCoordinatesCorrector} from "./ChatCoordinatesCorrector";
import {IDiagramNodeDto} from "../../../../common/apis/diagram/IDiagramNodeDto";

export class ChatCoordinatesManager {

    private chatCoordinatesCorrector = new ChatCoordinatesCorrector();

    public async fixAllChatCoordinatesForDiagram(diagramId: string) {
        await diagramService.findDiagramById(diagramId)
            .then(diagram => {
                return chatService.searchChatsByFilter({diagramId: diagramId})
                    .then(chats => {
                        return this.fixChatCoordinates(chats, diagram.nodes);
                    });
            })
            .catch(error => {
                if (error.status === 404) {
                    return;
                }
                throw error;
            });
    }

    public async fixChatCoordinatesForDiagram(diagramId: string, diagramNodes: IDiagramNodeDto[]) {
        const chats = await chatService.searchChatsByFilter({diagramId: diagramId});
        await this.fixChatCoordinates(chats, diagramNodes);
    }

    private async fixChatCoordinates(chats: ChatDto[], diagramNodes: IDiagramNodeDto[]) {
        const fixedChatCoordinates = this.chatCoordinatesCorrector.correctChatCoordinates(diagramNodes, chats);
        const updatePromises = fixedChatCoordinates.map(chatCoordinates => {
            return chatService.updateChatCoordinates(chatCoordinates.chatId, {
                x: chatCoordinates.x,
                y: chatCoordinates.y
            });
        });
        return Promise.all(updatePromises);
    }
}