import DescriptionIcon from "@mui/icons-material/Description";
import React from "react";
import {_transl} from "../../store/localization/TranslMessasge";
import {ExtGridTranslationKey} from "./ExtGridTranslationKey";
import {DiagramsGridAction, DiagramsGridActionType} from "../../pages/main/content/diagrams/DiagramsGridAction";
import RenderMode from "../../common/diagrameditor/context/RenderMode";
import RouteDefinitionUtils from "../../common/routedefinition/RouteDefinitionUtils";
import EditIcon from "@mui/icons-material/Edit";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

export enum ActionButtonType {
    IMMEDIATE = "IMMEDIATE",
    BULK_MENU = "BULK_MENU",
    BULK_MENU_ITEM = "BULK_MENU_ITEM",
    SPEED = "SPEED",
}

export enum EnabledPolicy {
    ALWAYS = "ALWAYS",
    WHEN_EXACTLY_ONE_SELECTED = "WHEN_EXACTLY_ONE_SELECTED",
    WHEN_AT_LEAST_ONE_SELECTED = "WHEN_AT_LEAST_ONE_SELECTED",
    WHEN_MORE_SELECTED = "WHEN_MORE_SELECTED",
}

type NonBulkType = Exclude<ActionButtonType, ActionButtonType.BULK_MENU | ActionButtonType.BULK_MENU_ITEM>;

export enum DefaultActionButtonId {
    SHOW_DETAIL = "$$ACTION_BUTTON_SHOW_DETAIL",
}

export interface GridAction {
    id: string,
    type: ActionButtonType,
    tooltip: string,
    icon?: JSX.Element,
    label?: string,
    childActions: GridAction[],
    enabledPolicy: EnabledPolicy,
    isEnabled?: (selectedRows: any[]) => boolean,
    isVisible?: (selectedRows: any[]) => boolean,
    onClick?: (selectedRowIds: any[], selectedRows: any[]) => void,
}

export class GridAction implements GridAction {

    constructor(public id: string,
                public type: ActionButtonType,
                public tooltip: string,
                public enabledPolicy: EnabledPolicy,
                public icon?: JSX.Element,
                public label?: string,
                public childActions: GridAction[] = [],
                public isEnabled?: (selectedRows: any[]) => boolean,
                public isVisible?: (selectedRows: any[]) => boolean,
                public onClick?: (selectedRowIds: any[], selectedRows: any[]) => void) {
        if (icon == null && label == null) {
            throw new Error("Either icon or label must be set!");
        }
    }

    public static buttonBuilder(id: string, type: NonBulkType, tooltip: string, icon?: JSX.Element) {
        const builder = new GridActionBuilder()
            .id(id)
            .type(type)
            .enabledPolicy(EnabledPolicy.WHEN_EXACTLY_ONE_SELECTED)
            .tooltip(tooltip);
        if (icon) {
            builder.icon(icon);
        }
        return builder;
    }

    public static detailButtonBuilder() {
        return GridAction.buttonBuilder(DefaultActionButtonId.SHOW_DETAIL, ActionButtonType.IMMEDIATE, _transl(ExtGridTranslationKey.SHOW_DETAIL))
            .enabledPolicy(EnabledPolicy.WHEN_EXACTLY_ONE_SELECTED)
            .icon(<DescriptionIcon/>);
    }

    public static showDiagramEditorButtonBuilder(editMode: boolean) {
        const tooltip = editMode ? _transl(ExtGridTranslationKey.OPEN_DIAGRAM_EDITOR) : _transl(ExtGridTranslationKey.OPEN_DIAGRAM_VIEW)

        return GridAction.buttonBuilder(DefaultActionButtonId.SHOW_DETAIL, ActionButtonType.IMMEDIATE, tooltip)
            .enabledPolicy(EnabledPolicy.WHEN_EXACTLY_ONE_SELECTED)
            .icon(editMode ? <EditIcon/> : <AccountTreeIcon/>)
            .onClick((selectedRowIds) => {
                const queryParams = [{
                    name: DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.SHOW_EDITOR),
                    value: selectedRowIds[0]
                }];
                if (editMode) {
                    queryParams.push({
                        name: DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.EDITOR_MODE),
                        value: RenderMode.EDIT
                    })
                }
                window.open(RouteDefinitionUtils.resolveDiagramEditorPath(queryParams), '_blank');
            });
    }

    public static bulkMenuBuilder(id: string, tooltip: string, icon: JSX.Element, childActions: GridAction[]) {
        return new GridActionBuilder()
            .id(id)
            .type(ActionButtonType.BULK_MENU)
            .tooltip(tooltip)
            .icon(icon)
            .childActions(childActions);
    }

    public static bulkMenuItemBuilder(id: string, label: string, icon?: JSX.Element) {
        const builder = new GridActionBuilder()
            .id(id)
            .type(ActionButtonType.BULK_MENU_ITEM)
            .enabledPolicy(EnabledPolicy.WHEN_AT_LEAST_ONE_SELECTED)
            .tooltip(label)
            .label(label);
        if (icon) {
            builder.icon(icon);
        }
        return builder;
    }
}

export class GridActionBuilder {
    private _id = "";
    private _type = ActionButtonType.IMMEDIATE;
    private _tooltip = "";
    private _icon?: JSX.Element;
    private _label?: string;
    private _isEnabled?: (selectedRows: any[]) => boolean;
    private _isVisible?: (selectedRows: any[]) => boolean;
    private _childActions: GridAction[] = [];
    private _enabledPolicy?: EnabledPolicy;
    private _onClick?: (selectedRowIds: any[], selectedRows: any[]) => void;

    id(id: string) {
        this._id = id;
        return this;
    }

    type(type: ActionButtonType) {
        this._type = type;
        return this;
    }

    tooltip(tooltip: string) {
        this._tooltip = tooltip;
        return this;
    }

    icon(icon: JSX.Element) {
        this._icon = icon;
        return this;
    }

    label(label: string) {
        this._label = label;
        return this;
    }

    isEnabled(isEnabled: (selectedRows: any[]) => boolean) {
        this._isEnabled = isEnabled;
        return this;
    }

    isVisible(isVisible: (selectedRows: any[]) => boolean) {
        this._isVisible = isVisible;
        return this;
    }

    childActions(childActions: GridAction[]) {
        this._childActions = childActions;
        return this;
    }

    enabledPolicy(enabledPolicy: EnabledPolicy) {
        this._enabledPolicy = enabledPolicy;
        return this;
    }

    onClick(onClick: (selectedRowIds: any[], selectedRows: any[]) => void) {
        this._onClick = onClick;
        return this;
    }

    build(): GridAction {
        if (this._icon == null && this._label == null) {
            throw new Error("Either icon or label must be set!");
        }
        let enabledPolicy: EnabledPolicy;
        if (this._enabledPolicy) {
            enabledPolicy = this._enabledPolicy;
        } else {
            enabledPolicy = EnabledPolicy.WHEN_EXACTLY_ONE_SELECTED
        }

        const button = new GridAction(this._id, this._type, this._tooltip, enabledPolicy, this._icon, this._label);
        button.isEnabled = this._isEnabled;
        button.isVisible = this._isVisible;
        button.childActions = this._childActions;
        button.onClick = this._onClick;

        return button;
    }
}
