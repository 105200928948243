import React, {Dispatch} from "react";
import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import {IApplicationState} from "../../../../store/Store";
import {connect} from "react-redux";
import {
    FetchStatusType,
    IFetchStatusState
} from "../../../../store/common/FetchableResource";
import {IFilter} from "../../../../store/diagrams/Diagrams";
import {CollectionDto} from "../../../../common/apis/collection/CollectionDto";
import MultiselectComboBox from "../../../../components/fields/MultiselectComboBox";
import {getDialogFilterResetAction} from "../../../../store/diagrams/DiagramsDialog";
import RenderMode, {RenderModeUtils} from "../diagrams/RenderMode";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {DiagramTranslationKey} from "./DiagramTranslationKey";
import {ViewpointDto} from "../../../../common/apis/ViewpointService";
import {FilterOptionsState, createFilterOptions} from "@mui/material/useAutocomplete";
import {ILabelDto} from "../../../../common/apis/label/ILabelDto";
import TextField from "../../../../components/fields/textfield/TextField";
import SearchFab from "../../../../components/button/SearchFab";
import Grid from "../../../../components/dialogs/Grid";
import PropertyFilterField from "../../../../components/fields/property/PropertyFilterField";

const filterStyles = (theme: Theme) => createStyles({
    filterSelect: {
    },
    formGridItem: {
        display: "flex",
        flexDirection: "column",
        "&> *": {
            flex: 1,
        }
    },
    searchActionGridItem: {
        display: 'flex',
        justifyContent: "flex-end",
    },
});

// State and props

interface IProps extends WithStyles<typeof filterStyles> {
    renderMode: RenderMode,
    doSearchOnLoad?: boolean,

    // store filter props
    lastFilter: IFilter,
    lastFilterUpdateDate?: Date,
    refetchFilter: () => void,
    updateFilter: (filter: IFilter) => void,
    dialogFilterReset: () => void,
    gridFetchStatus: IFetchStatusState,

    // store common options
    labelOptions: ILabelDto[],
    collectionOptions: CollectionDto[],
    viewpointOptions: ViewpointDto[],
}

interface IState {
    newFilter: IFilter
}

// Component class

class DiagramsFilter extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const { lastFilter } = this.props;
        this.state = {
            newFilter: {
                nameLike: lastFilter.nameLike || "",
                identifiers: lastFilter.identifiers || "",
                viewpoints: lastFilter.viewpoints || [],
                labels: lastFilter.labels || [],
                collections: lastFilter.collections || [],
                propertyFilters: lastFilter.propertyFilters || [],
            }
        }
    }

    componentDidMount() {
        if (this.props.lastFilterUpdateDate == null || this.props.doSearchOnLoad) {
            this.search();
        }
    }

    componentWillUnmount() {
        const {renderMode, dialogFilterReset} = this.props;
        if (renderMode === RenderMode.DIALOG) {
            // reset filter
            dialogFilterReset();
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.renderMode === RenderMode.PAGE && (prevProps.lastFilterUpdateDate !== this.props.lastFilterUpdateDate)) {
            this.props.refetchFilter();
        }
    }

    updateNewFilter<K extends keyof IFilter>(property: K, value: IFilter[K], callback?: () => void) {
        const afterCallback = callback != null ? callback : () => { };
        this.setState({
            newFilter: { ...this.state.newFilter, [property]: value }
        }, afterCallback);
    }

    updateSelectedFilterTab(tab: string) {
        this.setState({
            newFilter: { ...this.state.newFilter }
        })
    }

    search() {
        const newFilter = this.state.newFilter;
        const filter: IFilter = { ...newFilter };
        this.props.updateFilter(filter);
    }

    render() {
        const { classes, gridFetchStatus, collectionOptions, labelOptions, viewpointOptions } = this.props;
        const { newFilter } = this.state;
        const viewpointFilterOptions = createFilterOptions<ViewpointDto>();

        function transformUserInputsToViewpointOptions(changedValues: ViewpointDto[] | string[]): ViewpointDto[] {
            return changedValues.map((value: ViewpointDto | string) => transformToViewpoint(value));
        }

        function transformToViewpoint(value: ViewpointDto | string): ViewpointDto {
            return typeof value === 'string'
                ? {identifier: value, name: value}
                : value;
        }

        function setupFilterOptions(options: ViewpointDto[], params: FilterOptionsState<ViewpointDto>) {
            const filtered = viewpointFilterOptions(options, params);

            const {inputValue} = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.identifier);
            if (inputValue !== '' && !isExisting) {
                filtered.push({
                    identifier: inputValue,
                    name: _transl(DiagramTranslationKey.FILTER_ADD) + ` "${inputValue}"`
                });
            }

            return filtered;
        }

        const onSubmit = (event: React.FormEvent) => {
            event.preventDefault();
            this.search();
        };

        return (
            <>
                <form onSubmit={(event: React.FormEvent) => onSubmit(event)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3} lg={3} className={classes.formGridItem}>
                            <TextField
                                id="id-field"
                                label={_transl(DiagramTranslationKey.FILTER_IDENTIFIER)}
                                variant="outlined"
                                size={"small"}
                                value={newFilter.identifiers}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={e => this.updateNewFilter("identifiers", e)}
                                clearable
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} className={classes.formGridItem}>
                            <TextField
                                id="name-field"
                                label={_transl(DiagramTranslationKey.FILTER_TITLE)}
                                variant="outlined"
                                size={"small"}
                                value={newFilter.nameLike}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={e => this.updateNewFilter("nameLike", e)}
                                clearable
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} className={classes.formGridItem}>
                            <MultiselectComboBox
                                label={_transl(DiagramTranslationKey.FILTER_VIEWPOINTS)}
                                id="labels-multiselectcombobox"
                                className={classes.filterSelect}
                                options={viewpointOptions}
                                freeSolo={true}
                                filterOptions={(options, params) => setupFilterOptions(options, params)}
                                selectedValues={newFilter.viewpoints || []}
                                getRenderLabel={(value) => {
                                    return typeof value === 'string' ? value :
                                        value.name ? value.name : value.identifier;
                                }}
                                handleOnChange={(changedValues) => {
                                    const values = transformUserInputsToViewpointOptions(changedValues);
                                    this.updateNewFilter('viewpoints', values);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} className={classes.formGridItem}>
                            <MultiselectComboBox
                                label={_transl(DiagramTranslationKey.FILTER_LABELS)}
                                id="labels-multiselectcombobox"
                                className={classes.filterSelect}
                                options={labelOptions}
                                selectedValues={newFilter.labels || []}
                                getRenderLabel={(value) => value.name}
                                handleOnChange={(changedValues) => this.updateNewFilter('labels', changedValues)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} className={classes.formGridItem}>
                            <MultiselectComboBox
                                label={_transl(DiagramTranslationKey.FILTER_COLLECTIONS)}
                                id="collections-multiselectcombobox"
                                className={classes.filterSelect}
                                options={collectionOptions}
                                selectedValues={newFilter.collections || []}
                                getRenderLabel={(value) => value.name}
                                handleOnChange={(changedValues) => this.updateNewFilter('collections', changedValues)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} className={classes.formGridItem}>
                            <PropertyFilterField
                                propertyFilters={newFilter.propertyFilters}
                                handleOnChange={(changedValues) => this.updateNewFilter('propertyFilters', changedValues)}
                                type={"diagrams"}
                            />
                        </Grid>
                        <Grid item xs={12} sm md lg className={classes.searchActionGridItem}>
                            <SearchFab inProgress={gridFetchStatus.status === FetchStatusType.STARTED}/>
                        </Grid>
                    </Grid>
                </form>
            </>
        )
    }
}

// Redux store mapping

const mapStateToProps = (state: IApplicationState, ownProps: {renderMode: RenderMode}) => {
    const config = RenderModeUtils.getFilterConfig(ownProps.renderMode);

    return {
        // page specific
        lastFilter: config.getLastFilter(state),
        lastFilterUpdateDate: config.getLastFilterUpdateDate(state),
        gridFetchStatus: config.getGridFetchStatus(state),

        // common
        collectionOptions: state.pages.common.options.collections.resource,
        labelOptions: state.pages.common.options.labels.resource,
        viewpointOptions: state.pages.common.options.viewpoints.resource,
    }
}

const mapDispatchToProps = (dispatch: Dispatch<any>, ownProps: {renderMode: RenderMode}) => {
    const config = RenderModeUtils.getFilterConfig(ownProps.renderMode);

    return {
        refetchFilter: () => config.dispatchRefetchFilter(dispatch),
        updateFilter: (filter: IFilter) => config.dispatchUpdateFilter(dispatch, filter),
        dialogFilterReset: () => dispatch(getDialogFilterResetAction()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(filterStyles, { withTheme: true })(DiagramsFilter));
