import {IDiagramNodeDto} from "../../../../apis/diagram/IDiagramNodeDto";
import {ParentNodePosition} from "../ParentNodePosition";
import {IDiagramConnectionDto} from "../../../../apis/diagram/IDiagramConnectionDto";
import {RelationshipDto} from "../../../../apis/relationship/RelationshipDto";
import {ElementDto} from "../../../../apis/element/ElementDto";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import TranslationKey from "../../../TranslationKey";

export interface RemoveSelectedObjectsManager {
    getElementById: (id: string) => ElementDto | null;
    getRelationshipById: (id: string) => RelationshipDto | null;
    getDiagramNodes: () => Array<IDiagramNodeDto>;
    getDiagramConnections: () => Array<IDiagramConnectionDto>;
    getChildNodesInclusive: (node: IDiagramNodeDto) => Array<IDiagramNodeDto>;
    getNodeConnections: (node: IDiagramNodeDto) => Array<IDiagramConnectionDto>;
    getHiddenConnections: () => IDiagramConnectionDto[];
    filterOutDuplicitItems: <T>(items: Array<T>, getItemId: (item: T) => string) => Array<T>;
    createExistingNodeToParentNodePositionMap: (nodes: Array<IDiagramNodeDto>) => Map<string, ParentNodePosition>;
    removeModelItems: (nodesToRemove: Array<IDiagramNodeDto>,
                       nodeToParentNodePositionMap: Map<string, ParentNodePosition>,
                       elementsToRemove: Array<ElementDto>,
                       connectionsToRemove: Array<IDiagramConnectionDto>,
                       relationshipsToRemove: Array<RelationshipDto>,
                       isNewElement: boolean) => void;
    showError: (text: string) => void;
}

export default class RemoveSelectedObjectsHandler {

    private modelManager: RemoveSelectedObjectsManager;

    constructor(modelManager: RemoveSelectedObjectsManager) {
        this.modelManager = modelManager;
    }

    public removeSelectedObjects(selectedNodes: Array<IDiagramNodeDto>,
                                 selectedConnections: Array<IDiagramConnectionDto>,
                                 removeElementsAndRelationships: boolean) {
        try {
            const nodesToRemoveIncludingChildren = this.getNodesToRemoveIncludingChildren(selectedNodes);
            const nodesToRemove = this.getNodesToRemove(selectedNodes, nodesToRemoveIncludingChildren, removeElementsAndRelationships);
            const connectionsToRemove = this.getConnectionsToRemove(selectedConnections, nodesToRemove, removeElementsAndRelationships);
            const elementsToRemove = this.getElementsToRemove(removeElementsAndRelationships, nodesToRemoveIncludingChildren);
            const relationshipsToRemove = this.getRelationshipsToRemove(removeElementsAndRelationships, connectionsToRemove, nodesToRemoveIncludingChildren);
            const nodeToParentNodePositionMap = this.modelManager.createExistingNodeToParentNodePositionMap(nodesToRemove);

            if (this.shouldModelChange(elementsToRemove, relationshipsToRemove, nodesToRemove, connectionsToRemove)) {
                this.modelManager.removeModelItems(nodesToRemove, nodeToParentNodePositionMap, elementsToRemove, connectionsToRemove, relationshipsToRemove, false);
            }
        } catch (error) {
            this.modelManager.showError(_transl(TranslationKey.DIAGRAMS_DIAGRAMEDITOR_EDITOR_REMOVE_ITEMS_FROM_MODEL_FAILED));
        }
    }

    private getRelationshipsToRemove(removeElementsAndRelationships: boolean, connectionsToRemove: IDiagramConnectionDto[], nodesToRemove: IDiagramNodeDto[]) {
        const relationshipsToRemove: Array<RelationshipDto> = [];
        if (removeElementsAndRelationships) {
            const allConnectionsToRemove = [...connectionsToRemove];

            const nodeIds = new Set(nodesToRemove.map(node => node.identifier));
            const hiddenConnections = this.modelManager.getHiddenConnections();
            for (const hiddenConnection of hiddenConnections) {
                if (nodeIds.has(hiddenConnection.sourceIdentifier) || nodeIds.has(hiddenConnection.targetIdentifier)) {
                    allConnectionsToRemove.push(hiddenConnection);
                }
            }

            for (const connection of allConnectionsToRemove) {
                if (connection.relationshipIdentifier) {
                    const relationship = this.modelManager.getRelationshipById(connection.relationshipIdentifier);
                    if (relationship) {
                        relationshipsToRemove.push(relationship);
                    }
                }
            }
        }
        return this.modelManager.filterOutDuplicitItems<RelationshipDto>(relationshipsToRemove, item => item.identifier);
    }

    private getElementsToRemove(removeElementsAndRelationships: boolean, nodesToRemove: Array<IDiagramNodeDto>) {
        const elementsToRemove: Array<ElementDto> = [];
        if (removeElementsAndRelationships) {
            for (const node of nodesToRemove) {
                if (node.elementIdentifier) {
                    const element = this.modelManager.getElementById(node.elementIdentifier);
                    if (element) {
                        elementsToRemove.push(element);
                    }
                }
            }
        }
        return elementsToRemove;
    }

    private getNodesToRemoveIncludingChildren(selectedNodes: Array<IDiagramNodeDto>) {
        const selectedNodesIncludingChildren = selectedNodes.flatMap(node => this.modelManager.getChildNodesInclusive(node));
        return this.modelManager.filterOutDuplicitItems<IDiagramNodeDto>(selectedNodesIncludingChildren, node => node.identifier);
    }

    private getNodesToRemove(selectedNodes: Array<IDiagramNodeDto>, nodesToRemoveIncludingChildren: Array<IDiagramNodeDto>,
                             removeElementsAndRelationships: boolean) {
        if (removeElementsAndRelationships) {
            return this.modelManager.filterOutDuplicitItems<IDiagramNodeDto>(
                this.getNodesToRemoveIncludingDuplicates(selectedNodes).concat(nodesToRemoveIncludingChildren),
                node => node.identifier);
        } else {
            return nodesToRemoveIncludingChildren;
        }
    }

    private getNodesToRemoveIncludingDuplicates(selectedNodes: Array<IDiagramNodeDto>) {
        const elementIds = new Set<string>();
        for (let i = 0; i < selectedNodes.length; i++) {
            if (selectedNodes[i].elementIdentifier) {
                elementIds.add(selectedNodes[i].elementIdentifier!);
            }
        }
        return this.modelManager.getDiagramNodes().filter(value => value.elementIdentifier && elementIds.has(value.elementIdentifier));
    }

    private getConnectionsToRemove(selectedConnections: IDiagramConnectionDto[], nodesToRemove: IDiagramNodeDto[],
                                   removeElementsAndRelationships: boolean) {
        const allConnections = [];
        if (removeElementsAndRelationships) {
            const relationshipIds = new Set<string>();
            for (const selectedConnection of selectedConnections) {
                if (selectedConnection.relationshipIdentifier) {
                    relationshipIds.add(selectedConnection.relationshipIdentifier!);
                } else {
                    allConnections.push(selectedConnection);
                }
            }
            allConnections.push(...this.modelManager.getDiagramConnections().filter(value => value.relationshipIdentifier
                && relationshipIds.has(value.relationshipIdentifier)));
        } else {
            allConnections.push(...selectedConnections);
        }
        allConnections.push(...nodesToRemove.flatMap(node => this.modelManager.getNodeConnections(node)));
        return this.modelManager.filterOutDuplicitItems<IDiagramConnectionDto>(allConnections, connection => connection.identifier);
    }

    private shouldModelChange(elementsToBeRemoved: Array<ElementDto>,
                              relationshipsToBeRemoved: Array<RelationshipDto>,
                              nodesToBeRemoved: Array<IDiagramNodeDto>,
                              connectionsToBeRemoved: Array<IDiagramConnectionDto>) {
        return elementsToBeRemoved.length > 0 || relationshipsToBeRemoved.length > 0 || nodesToBeRemoved.length > 0 || connectionsToBeRemoved.length > 0;
    }

}