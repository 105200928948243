import SearchIcon from "@mui/icons-material/Search";
import {CircularProgress, Fab} from "@mui/material";
import React from "react";
import {Theme} from "@mui/material/styles";
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchFab: {
            color: 'white',
            width: "3em",
            height: "3em"
        },
    })
);

interface SearchFabProperties {
    inProgress?: boolean;
}

export default function SearchFab(props: SearchFabProperties) {
    const {inProgress} = props;
    const classes = useStyles();
    return (
        <Fab aria-label="search" className={classes.searchFab} color={"primary"} disabled={inProgress} type={"submit"}>
            {!inProgress && <SearchIcon/>}
            {inProgress && <CircularProgress size={"1em"}/>}
        </Fab>
    );
}